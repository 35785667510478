import { keepNumber } from '@assets/js/regExUtil';

export const tableProperties = [
  {
    label: '采购部门',
    prop: 'modr_dept_name',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  {
    label: '订单总数',
    prop: 'order_sum',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '成品货号总数',
    prop: 'podr_count',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '辅料货号总数',
    prop: 'modr_part_count',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '辅料总数量',
    prop: 'modr_sum',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '材料合同总额',
    prop: 'modr_price_sum',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    align: 'right'
  },
  {
    label: '准时率(%)',
    prop: 'on_time_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.on_time_rate = keepNumber(val))
    }
  },
  {
    label: '合格率(%)',
    prop: 'pass_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.pass_rate = keepNumber(val))
    }
  },
  {
    label: '工厂配合度(%)',
    prop: 'factory_cooperation_degree',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.factory_cooperation_degree = keepNumber(val))
    }
  },
  {
    label: '生产配合度打分',
    prop: 'production_cooperation_degree',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      disabled: false,
      type: 'input',
      maxLength: 3,
      input: (val, row) => (row.production_cooperation_degree = keepNumber(val))
    }
  }
];
